import isEqual from 'lodash-es/isEqual'
import AlphaHelpers from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-helpers'

document.addEventListener('alpha.loaded', (e) => {
  const moduleMgr = e.detail.emitter
  const config = moduleMgr.getConfig()

  moduleMgr.addModuleEventListener('leafletMap', 'map.loaded', (ev) => {
    const mapModule = ev.emitter
    const mapInfo = ev.mapInfo
    const map = mapInfo.map
    const mapEl = getMapElement()
    const options = getOptions()
    if (mapEl && mapEl === map.getContainer() && options) {
      // fit to max bounds
      if (options.maxBounds) {
        const mapPaddingRatio = mapModule.getConfig().mapPaddingRatio
        const maxBounds = L.latLngBounds(options.maxBounds)

        const paddedMaxBounds = maxBounds.pad(mapPaddingRatio)
        map.setMaxBounds(maxBounds)
        map.fitBounds(maxBounds)
      }

      let lastClickedLatLng = null
      let marker = null

      map.on('click', (event) => {
        const latLng = event.latlng

        // on mobile there's a double tap, prevent placing several markers at once
        if (isEqual(latLng, lastClickedLatLng)) {
          return
        }

        lastClickedLatLng = latLng

        // check if clicked point is within map bounds (if any)
        if (!pointIsWithinMapBounds(latLng)) {
          window.alphaNotifier.addNotice(config.i18n.outOfMapBounds, 'error')
          return
        }

        // extract coordinates and place marker
        const lat = AlphaHelpers.roundFloat(latLng.lat, options.precision)
        const lng = AlphaHelpers.roundFloat(latLng.lng, options.precision)

        marker = addMarker(lat, lng, map, marker)

        // update hidden fields
        updateInputFields(lat, lng)
      })

      const geolocationBtnEl = document.querySelector('.btn-geolocation')
      if (geolocationBtnEl) {
        geolocationBtnEl.addEventListener('click', (e) => {
          e.preventDefault()

          if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
              const lat = position.coords.latitude
              const lng = position.coords.longitude
              const latLng = L.latLng(lat, lng)

              // check if clicked point is within map bounds (if any)
              if (!pointIsWithinMapBounds(latLng)) {
                window.alphaNotifier.addNotice(config.i18n.outOfMapBounds, 'error')
                return
              }

              marker = addMarker(lat, lng, map, marker)

              // update hidden fields
              updateInputFields(lat, lng)
            })
          }
        })
      }
    }
  })
})

function pointIsWithinMapBounds(latLng) {
  const options = getOptions()
  if (options.maxBounds) {
    const maxBounds = L.latLngBounds(options.maxBounds)
    if (!maxBounds.contains(latLng)) {
      return false
    }
  }

  return true
}

function getLatInputElement() {
  return document.querySelector('[name="problem_lat"]')
}

function getLngInputElement() {
  return document.querySelector('[name="problem_lng"]')
}

function addMarker(lat, lng, map, marker = null) {
  // remove old marker if necessary
  if (marker) {
    removeMarker(marker, map)
  }

  // place new marker
  marker = L.marker([lat, lng], {
    icon: L.ExtraMarkers.icon({
      icon: 'fas fa-info',
      prefix: '',
      svg: true
    })
  })

  marker.addTo(map)

  return marker
}

function removeMarker(marker, map) {
  map.removeLayer(marker)
}

function updateInputFields(lat, lng) {
  const latInputEl = getLatInputElement()
  const lngInputEl = getLngInputElement()
  if (latInputEl && lngInputEl) {
    latInputEl.value = lat
    lngInputEl.value = lng
  }
}

function getRootElement() {
  const mapElement = getMapElement()
  if (mapElement) {
    return mapElement.parentElement
  }

  return null
}

function getMapElement() {
  return document.getElementById('problem_map')
}

function getOptions() {
  const rootEl = getRootElement()
  if (rootEl) {
    const options = AlphaHelpers.getParsedJsonAttribute(rootEl, 'data-map-options')
    if (options) {
      return options
    }
  }

  return null
}
